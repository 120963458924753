/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OpenMicList_list = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly name: string;
            readonly location: string;
            readonly location_point: unknown | null;
            readonly datetime: unknown;
            readonly pin: unknown;
            readonly owner_id: string;
        };
    }>;
    readonly " $refType": "OpenMicList_list";
};
export type OpenMicList_list$data = OpenMicList_list;
export type OpenMicList_list$key = {
    readonly " $data"?: OpenMicList_list$data;
    readonly " $fragmentRefs": FragmentRefs<"OpenMicList_list">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenMicList_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "open_micEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "open_mic",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "location",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "location_point",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "datetime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "owner_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "open_micConnection",
  "abstractKey": null
};
(node as any).hash = 'ae87d5a1cec4f95d145f8eef6e79482c';
export default node;
