import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Button, Grid, Typography, Divider, Link } from "@material-ui/core";
import LinkedCameraIcon from "@material-ui/icons/LinkedCamera";
import moment from "moment";
import { useParams } from "react-router-dom";
import path from "path";
import { useLazyLoadQuery, useSubscription } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import firebase from "firebase/app";
import "firebase/auth";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import QRCode from "qrcode.react";

import OpenMicPerformerList from "./OpenMicPerformerList";
import { ReactComponent as LitMicIcon } from "../../shared/LitMicIcon.svg";
import type { MicDetailsQuery } from "./__generated__/MicDetailsQuery.graphql";
import { useInterval } from "../../shared/functions/useInterval";

const subscription = graphql`
  subscription MicDetailsSubscription($pin: bpchar!) {
    open_mic_connection(where: { pin: { _eq: $pin } }, first: 1) {
      edges {
        node {
          id
          name
          location
          datetime
          pin
          owner_id
          open_signup_at
          close_signup_at
          ...OpenMicPerformerList_performers
        }
      }
    }
  }
`;

function MicDetails() {
  const history = useHistory();
  const { pin } = useParams<{ pin: string }>();
  const [qrModalOpen, setQrModalOpen] = useState(false);

  // Force rerender every minute, refreshes signup button (not sure if worth)
  // https://stackoverflow.com/a/58606536
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  useInterval(forceUpdate, 60000);

  // https://relay.dev/docs/en/experimental/api-reference#usesubscription
  const config = useMemo(() => ({ variables: { pin }, subscription }), [pin]);
  useSubscription(config);
  // Confusing af: https://relay.dev/docs/en/experimental/a-guided-tour-of-relay#queries
  const { open_mic_connection } = useLazyLoadQuery<MicDetailsQuery>(
    graphql`
      query MicDetailsQuery($pin: bpchar!) {
        open_mic_connection(where: { pin: { _eq: $pin } }, first: 1) {
          edges {
            node {
              id
              name
              location
              location_point
              datetime
              pin
              owner_id
              open_signup_at
              close_signup_at
              ...OpenMicPerformerList_performers
            }
          }
        }
      }
    `,
    { pin }
  );
  const openMic = open_mic_connection?.edges?.[0]?.node;

  const openMicAny = openMic as any; // Idk, avoiding "complex expression in the dependency array"
  useEffect(() => {
    if (!openMicAny) {
      setTimeout(() => history.goBack(), 2000);
    }
  }, [history, openMicAny]);

  if (!openMic) {
    return (
      <Typography variant="h4" align="center">
        Open Mic Not Found!
      </Typography>
    );
  }

  const signupsEnabled = moment().isBetween(
    openMic.open_signup_at as string,
    openMic.close_signup_at as string
  );

  const user = firebase.auth().currentUser;
  const isOwner = openMic.owner_id === user?.uid;

  const getSignupTimeDisplay = (signupTime: string, compareTime: string) => {
    const signupMoment = moment(signupTime);
    const compareMoment = moment(compareTime);
    if (Math.abs(signupMoment.diff(compareMoment)) > 24 * 60 * 60 * 1000) {
      return signupMoment.format("M/D@h:mm");
    }
    return signupMoment.format("h:mm a");
  };

  return (
    <Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={12} style={{ padding: ".5em .5em 1.5em .5em" }}>
          <Link to={"/"} component={RouterLink} underline="none">
            <LitMicIcon height="3em" />
          </Link>
          <Button
            component={RouterLink}
            to={path.join("/signup", pin)}
            color="primary"
            variant="contained"
            style={{ float: "right" }}
            disabled={!signupsEnabled}
          >
            Sign Up
          </Button>
          {isOwner && (
            <Button
              component={RouterLink}
              to={path.join("/edit", pin)}
              color="secondary"
              variant="contained"
              style={{ float: "right", marginRight: ".8em" }}
            >
              Edit
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography align="center" variant="h5" style={{ fontWeight: 600 }}>
            {openMic.name}
          </Typography>
          <Typography align="center" variant="body1">
            {moment(openMic.datetime as string).format("MMMM Do, h:mm a")}
          </Typography>

          {/* Verbose code just to get no link when no coords - maybe worth its own component 
           Also: https://github.com/ReactTraining/react-router/issues/1147 */}
          {openMic.location_point ? (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=
                ${encodeURIComponent(openMic.location)}
              `}
            >
              <Typography align="center" variant="body1">
                {openMic.location}
              </Typography>
            </a>
          ) : (
            <Typography align="center" variant="body1">
              {openMic.location}
            </Typography>
          )}

          <Typography align="center" variant="body1">
            Signups Open:{" "}
            {getSignupTimeDisplay(
              openMic.open_signup_at as string,
              openMic.datetime as string
            )}
            {" - "}
            {getSignupTimeDisplay(
              openMic.close_signup_at as string,
              openMic.open_signup_at as string
            )}
          </Typography>

          <Typography
            align="center"
            variant="body1"
            style={{ fontWeight: 600 }}
            onClick={() => setQrModalOpen(true)}
          >
            PIN: {openMic.pin}{" "}
            <LinkedCameraIcon style={{ position: "relative", top: "4px" }} />
          </Typography>

          <Divider style={{ margin: "1em 0 1.5em 0" }} />

          <Typography
            align="center"
            variant="h6"
            style={{ fontWeight: 600, marginBottom: ".3em" }}
          >
            Performers
          </Typography>
          {
            <OpenMicPerformerList
              isOwner={isOwner}
              performers={openMic as any}
            />
          }
        </Grid>
      </Grid>
      <Modal open={qrModalOpen} onClose={() => setQrModalOpen(false)} center>
        <QRCode value={window.location.href} style={{ margin: "5vh" }} />
      </Modal>
    </Fragment>
  );
}

export default MicDetails;
