import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLazyLoadQuery } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import useGeolocation from "@rooks/use-geolocation";
import { NearbyMicsQuery } from "./__generated__/NearbyMicsQuery.graphql";
import moment from "moment";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import OpenMicList from "../../shared/components/OpenMicList";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}));

const NearbyMics = () => {
  const classes = useStyles();
  const geoObj = useGeolocation();
  const [rangeMiles, setRangeMiles] = React.useState(5);

  const { nearby_open_mics_connection } = useLazyLoadQuery<NearbyMicsQuery>(
    graphql`
      query NearbyMicsQuery(
        $args: nearby_open_mics_args!
        $earliest: timestamptz!
      ) {
        nearby_open_mics_connection(
          args: $args
          order_by: { datetime: asc }
          where: { datetime: { _gte: $earliest } }
        ) {
          ...OpenMicList_list
        }
      }
    `,
    {
      args: {
        lat: geoObj?.lat ?? 0,
        lng: geoObj?.lng ?? 0,
        distance_mi: rangeMiles,
      },
      earliest: moment().startOf("hour").toDate(),
    }
  );

  return (
    <Fragment>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel>Range</InputLabel>
        <Select
          native
          value={rangeMiles}
          onChange={(e) => setRangeMiles(e.target.value as number)}
        >
          <option value={5}>5 Miles</option>
          <option value={10}>10 Miles</option>
          <option value={25}>25 Miles</option>
          <option value={50}>50 Miles</option>
        </Select>
      </FormControl>

      <OpenMicList openMics={nearby_open_mics_connection} />
    </Fragment>
  );
};

export default NearbyMics;
