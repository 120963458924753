import React from "react";
import { ReactSortable } from "react-sortablejs";
import List from "@material-ui/core/List";
import {
  Checkbox,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import { useFragment, useMutation } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import {
  OpenMicPerformerList_performers,
  OpenMicPerformerList_performers$key,
} from "./__generated__/OpenMicPerformerList_performers.graphql";
import { OpenMicPerformerListDeletePerformerMutation } from "./__generated__/OpenMicPerformerListDeletePerformerMutation.graphql";
import { OpenMicPerformerListMutation } from "./__generated__/OpenMicPerformerListMutation.graphql";
import { decodeRelayId } from "../../shared/functions/decodeRelayId";
import { nonNullish } from "../../shared/functions/nonNullish";
import { OpenMicPerformerListPerformanceCompleteMutation } from "./__generated__/OpenMicPerformerListPerformanceCompleteMutation.graphql";

type Writeable<T> = { -readonly [P in keyof T]: T[P] };
type PerformerType = Writeable<
  NonNullable<
    NonNullable<
      OpenMicPerformerList_performers["performers_connection"]["edges"]
    >[0]
  >["node"]
>;

const OpenMicPerformerList = (props: {
  isOwner: boolean;
  performers: OpenMicPerformerList_performers$key;
}) => {
  const { isOwner } = props;
  const { performers_connection } = useFragment(
    graphql`
      fragment OpenMicPerformerList_performers on open_mic {
        performers_connection {
          edges {
            node {
              id
              _order
              name
              intro_notes
              open_mic_id
              user_id
              performance_complete
            }
          }
        }
      }
    `,
    props.performers
  );

  // Filter is for deleted performers
  const performers =
    performers_connection?.edges
      ?.map((n) => n.node)
      .filter(nonNullish)
      .sort((p1, p2) => (p1._order ?? 1) - (p2._order ?? 1)) ?? [];

  // // https://relay.dev/docs/en/api-reference#usemutation
  const [
    deletePerformerMutation,
    deleteInFlight,
  ] = useMutation<OpenMicPerformerListDeletePerformerMutation>(graphql`
    mutation OpenMicPerformerListDeletePerformerMutation($id: uuid!) {
      delete_performer_by_pk(id: $id) {
        id
      }
    }
  `);

  // TODO: This mutation is a hacky way to reorder all performers in one API call
  const [
    updatePerformerList,
    updateInFlight,
  ] = useMutation<OpenMicPerformerListMutation>(graphql`
    mutation OpenMicPerformerListMutation(
      $performers: [performer_insert_input!]!
      $orderIncrement: Int!
      $openMicId: uuid!
    ) {
      update_performer(
        where: { _order: { _gte: 0 }, open_mic_id: { _eq: $openMicId } }
        _inc: { _order: $orderIncrement }
      ) {
        affected_rows
      }
      insert_performer(
        objects: $performers
        on_conflict: { constraint: performer_pkey, update_columns: _order }
      ) {
        affected_rows
        returning {
          id
          _order
          name
          intro_notes
          open_mic_id
          user_id
          performance_complete
        }
      }
    }
  `);

  const [
    updatePerformanceCompleteMutation,
    updatePerformanceCompleteInFlight,
  ] = useMutation<OpenMicPerformerListPerformanceCompleteMutation>(graphql`
    mutation OpenMicPerformerListPerformanceCompleteMutation(
      $id: uuid!
      $performanceComplete: Boolean!
    ) {
      update_performer_by_pk(
        pk_columns: { id: $id }
        _set: { performance_complete: $performanceComplete }
      ) {
        id
        performance_complete
      }
    }
  `);

  const mutateInFlight =
    deleteInFlight || updateInFlight || updatePerformanceCompleteInFlight;

  // TODO: Memoize functions & performerList
  const removePerformer = (performerId: string) => {
    if (!mutateInFlight)
      deletePerformerMutation({
        variables: {
          id: decodeRelayId(performerId),
        },
        updater: (store) => store.delete(performerId),
        optimisticResponse: { delete_performer_by_pk: { id: performerId } },
      });
  };

  const updatePerformanceComplete = (
    performerId: string,
    performanceComplete: boolean
  ) => {
    if (mutateInFlight) return;
    updatePerformanceCompleteMutation({
      variables: {
        id: decodeRelayId(performerId),
        performanceComplete,
      },
      optimisticResponse: {
        update_performer_by_pk: {
          id: performerId,
          performance_complete: performanceComplete,
        },
      },
    });
  };

  const updateOrder = (newPerformerList: PerformerType[]) => {
    const performerIds = newPerformerList.map((p) => p.id);
    const performerOrder = performerIds.join(",");
    if (performerOrder === performers.map((p) => p.id).join(",")) {
      return; // No update (Happens on pageload?)
    }
    const newPerformers = newPerformerList.map((performer, idx) => ({
      ...performer,
      id: decodeRelayId(performer.id.toString()),
      _order: idx + 1,
    }));
    const orderIncrement = newPerformerList.length * newPerformerList.length; // Hacky thing to reorder on hasura in one API call
    if (!mutateInFlight)
      updatePerformerList({
        variables: {
          performers: newPerformers,
          orderIncrement,
          openMicId: performers[0].open_mic_id,
        },
        optimisticResponse: {
          update_performer: { affected_rows: newPerformerList.length },
          insert_performer: {
            affected_rows: newPerformerList.length,
            returning: newPerformerList.map((p, idx) => ({
              ...p,
              _order: idx + 1,
            })),
          },
        },
      });
  };

  return (
    <List aria-label="performer list">
      <Divider />
      {/* key prop is a hack https://github.com/SortableJS/react-sortablejs/issues/153 */}
      <ReactSortable
        list={performers}
        setList={updateOrder}
        disabled={!isOwner || mutateInFlight}
        key={isOwner.toString()}
        handle=".performer-handle"
      >
        {performers.map(
          (
            {
              id,
              name,
              intro_notes: introNotes,
              performance_complete: performanceComplete,
            },
            idx
          ) =>
            isOwner ? (
              <ListItem
                divider={idx !== performers.length - 1}
                key={id}
                style={{ borderTop: "5px" }}
              >
                <ListItemIcon>
                  <DragHandleIcon className="performer-handle" />
                </ListItemIcon>

                <ListItemText primary={name} secondary={introNotes} />

                <Checkbox
                  checked={performanceComplete}
                  onChange={(e, checked) => {
                    if (isOwner) updatePerformanceComplete(id, checked);
                  }}
                />

                <DeleteIcon
                  onClick={() => removePerformer(id)}
                  style={{ float: "right", marginLeft: ".5em" }}
                  color="action"
                />
              </ListItem>
            ) : (
              <ListItem key={id} divider={idx !== performers.length - 1}>
                <ListItemText primary={name} secondary={introNotes} />
                <Checkbox checked={performanceComplete} disabled />
              </ListItem>
            )
        )}
      </ReactSortable>
    </List>
  );
};

export default OpenMicPerformerList;
