import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";

import { useParams } from "react-router-dom";
import path from "path";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import firebase from "firebase/app";
import "firebase/auth";
import "react-responsive-modal/styles.css";
import {
  EditMicMutation,
  open_mic_set_input,
} from "./__generated__/EditMicMutation.graphql";
import { EditMicQuery } from "./__generated__/EditMicQuery.graphql";
import { LocationValueType } from "../../shared/components/LocationAutocomplete";
import MicOptions from "../../shared/components/MicOptions";
import ButtonCircularProgress from "../../shared/components/ButtonCircularProgress";
import { decodeRelayId } from "../../shared/functions/decodeRelayId";

function EditMic() {
  const history = useHistory();
  const { pin } = useParams<{ pin: string }>();

  // Could use a shared GraphQL fragment for mutation+query
  const [
    updateOpenMicMutation,
    updateInFlight,
  ] = useMutation<EditMicMutation>(graphql`
    mutation EditMicMutation($id: uuid!, $setValues: open_mic_set_input!) {
      update_open_mic_by_pk(_set: $setValues, pk_columns: { id: $id }) {
        id
        name
        location
        location_point
        datetime
        pin
        open_signup_at
        close_signup_at
      }
    }
  `);

  // Confusing af: https://relay.dev/docs/en/experimental/a-guided-tour-of-relay#queries
  const { open_mic_connection } = useLazyLoadQuery<EditMicQuery>(
    graphql`
      query EditMicQuery($pin: bpchar!) {
        open_mic_connection(where: { pin: { _eq: $pin } }, first: 1) {
          edges {
            node {
              id
              name
              location
              location_point
              datetime
              pin
              owner_id
              open_signup_at
              close_signup_at
            }
          }
        }
      }
    `,
    { pin }
  );
  const openMic = open_mic_connection?.edges?.[0]?.node;

  const [micDatetime, setMicDatetime] = useState(
    new Date(openMic?.datetime as any)
  );
  const [micOpenSignupDatetime, setMicOpenSignupDatetime] = useState(
    new Date(openMic?.open_signup_at as any)
  );
  const [micCloseSignupDatetime, setMicCloseSignupDatetime] = useState(
    new Date(openMic?.close_signup_at as any)
  );
  const [micLocation, setMicLocation] = useState<LocationValueType>({
    location: openMic?.location as any,
  });

  // Would be nice to default this in a better way
  const micName = useRef<HTMLInputElement>();
  useEffect(() => {
    if (micName.current) {
      micName.current.value = openMic.name;
    }
  }, [micName, openMic.name]);

  // Following logic could be DRY'd
  const openMicAny = openMic as any; // Idk, avoiding "complex expression in the dependency array"
  const user = firebase.auth().currentUser; // This dont work good
  const isOwner = openMic.owner_id === user?.uid;
  useEffect(() => {
    if (openMicAny && !isOwner) {
      setTimeout(() => history.goBack(), 2000);
    }
  }, [history, openMicAny, isOwner]);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!updateInFlight) {
        const user = firebase.auth().currentUser;

        if (!user || user.isAnonymous) {
          return alert("Sign in to create an open mic");
        }

        const setValues: open_mic_set_input = {
          datetime: micDatetime,
          location: micLocation.location,
          name: micName.current?.value as string,
          open_signup_at: micOpenSignupDatetime,
          close_signup_at: micCloseSignupDatetime,
        };

        if (micLocation.lat != null && micLocation.lng != null)
          setValues.location_point = {
            type: "Point",
            coordinates: [micLocation.lng, micLocation.lat], // PostGIS order
            crs: { type: "name", properties: { name: "EPSG:4326" } },
          };
        updateOpenMicMutation({
          variables: {
            id: decodeRelayId(openMic.id),
            setValues,
          },
          onCompleted: (response) => {
            response.update_open_mic_by_pk?.pin &&
              history.push(
                path.join("/mic", response.update_open_mic_by_pk.pin as string)
              );
          },
        });
      }
    },
    [
      history,
      micDatetime,
      micOpenSignupDatetime,
      micCloseSignupDatetime,
      micLocation,
      updateOpenMicMutation,
      updateInFlight,
      openMic,
    ]
  );

  if (!openMic) {
    return (
      <Typography variant="h4" align="center">
        Open Mic Not Found!
      </Typography>
    );
  }

  if (!isOwner) {
    return (
      <Typography variant="h4" align="center">
        Active User Cannot Edit Mic
      </Typography>
    );
  }

  return (
    <Fragment>
      <Typography variant="h3" align="center" className="sm-mg-bottom">
        Edit Mic
      </Typography>
      <form onSubmit={onFormSubmit}>
        <MicOptions
          disablePast={false}
          micDatetime={micDatetime}
          onChangeMicDatetime={setMicDatetime}
          micOpenSignupDatetime={micOpenSignupDatetime}
          onChangeMicOpenSignupDatetime={setMicOpenSignupDatetime}
          micCloseSignupDatetime={micCloseSignupDatetime}
          onChangeMicCloseSignupDatetime={setMicCloseSignupDatetime}
          micName={micName}
          micLocation={micLocation}
          onChangeMicLocation={setMicLocation}
        />
        <br />
        <Grid container justify="flex-end">
          <Button onClick={() => history.goBack()}>Back</Button>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={updateInFlight}
          >
            Save {updateInFlight && <ButtonCircularProgress />}
          </Button>
        </Grid>
      </form>
    </Fragment>
  );
}

export default EditMic;
