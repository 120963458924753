import React, { Fragment } from "react";
import { List, ListItem, TextField } from "@material-ui/core";
import DateTimePicker from "./DateTimePicker";
import LocationAutocomplete from "./LocationAutocomplete";
import moment from "moment";

const DEFAULT_OPEN_SIGNUP_OFFSET = 30;
const DEFAULT_CLOSE_SIGNUP_OFFSET = 60;

function MicOptions(props: any) {
  const {
    micDatetime,
    onChangeMicDatetime,
    micOpenSignupDatetime,
    onChangeMicOpenSignupDatetime,
    micCloseSignupDatetime,
    onChangeMicCloseSignupDatetime,
    micName,
    micLocation,
    onChangeMicLocation,
    disablePast = true,
  } = props;

  const handleChangeDatetime = (newDatetime: Date) => {
    onChangeMicDatetime(newDatetime);
    onChangeMicOpenSignupDatetime(
      moment(newDatetime)
        .subtract(DEFAULT_OPEN_SIGNUP_OFFSET, "minutes")
        .toDate()
    );
    onChangeMicCloseSignupDatetime(
      moment(newDatetime).add(DEFAULT_CLOSE_SIGNUP_OFFSET, "minutes").toDate()
    );
  };

  const handleChangeOpenSignupDatetime = (newDatetime: Date) => {
    if (moment(newDatetime).isAfter(micCloseSignupDatetime)) {
      onChangeMicCloseSignupDatetime(newDatetime);
    }
    onChangeMicOpenSignupDatetime(newDatetime);
  };

  const handleChangeCloseSignupDatetime = (newDatetime: Date) => {
    if (moment(newDatetime).isBefore(micOpenSignupDatetime)) {
      onChangeMicOpenSignupDatetime(newDatetime);
    }
    onChangeMicCloseSignupDatetime(newDatetime);
  };

  return (
    <Fragment>
      <List disablePadding>
        <ListItem>
          <TextField required label="Mic Name" inputRef={micName} fullWidth />
        </ListItem>
        <ListItem>
          <DateTimePicker
            required
            label="Mic Date/Time"
            value={micDatetime}
            onChange={handleChangeDatetime}
            disablePast={disablePast}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <LocationAutocomplete
            required
            value={micLocation}
            onChange={onChangeMicLocation}
          />
        </ListItem>
        <ListItem>
          <DateTimePicker
            required
            label="Signups Open At:"
            value={micOpenSignupDatetime}
            onChange={handleChangeOpenSignupDatetime}
            disablePast={disablePast}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <DateTimePicker
            required
            label="Signups Close At:"
            value={micCloseSignupDatetime}
            onChange={handleChangeCloseSignupDatetime}
            disablePast={disablePast}
            fullWidth
          />
        </ListItem>
      </List>
    </Fragment>
  );
}

export default MicOptions;
