/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type open_mic_constraint = "open_mic_pin_key" | "open_mic_pkey" | "%future added value";
export type open_mic_update_column = "close_signup_at" | "created_at" | "datetime" | "id" | "is_public" | "location" | "location_point" | "name" | "open_signup_at" | "owner_id" | "pin" | "updated_at" | "%future added value";
export type performer_constraint = "performer_open_mic__order_key" | "performer_pkey" | "%future added value";
export type performer_update_column = "_order" | "created_at" | "id" | "intro_notes" | "name" | "open_mic_id" | "performance_complete" | "updated_at" | "user_id" | "%future added value";
export type open_mic_insert_input = {
    close_signup_at?: unknown | null;
    created_at?: unknown | null;
    datetime?: unknown | null;
    id?: unknown | null;
    is_public?: boolean | null;
    location?: string | null;
    location_point?: unknown | null;
    name?: string | null;
    open_signup_at?: unknown | null;
    owner_id?: string | null;
    performers?: performer_arr_rel_insert_input | null;
    pin?: unknown | null;
    updated_at?: unknown | null;
};
export type performer_arr_rel_insert_input = {
    data: Array<performer_insert_input>;
    on_conflict?: performer_on_conflict | null;
};
export type performer_insert_input = {
    _order?: number | null;
    created_at?: unknown | null;
    id?: unknown | null;
    intro_notes?: string | null;
    name?: string | null;
    openMic?: open_mic_obj_rel_insert_input | null;
    open_mic_id?: unknown | null;
    performance_complete?: boolean | null;
    updated_at?: unknown | null;
    user_id?: string | null;
};
export type open_mic_obj_rel_insert_input = {
    data: open_mic_insert_input;
    on_conflict?: open_mic_on_conflict | null;
};
export type open_mic_on_conflict = {
    constraint: open_mic_constraint;
    update_columns: Array<open_mic_update_column>;
    where?: open_mic_bool_exp | null;
};
export type open_mic_bool_exp = {
    _and?: Array<open_mic_bool_exp> | null;
    _not?: open_mic_bool_exp | null;
    _or?: Array<open_mic_bool_exp> | null;
    close_signup_at?: timestamptz_comparison_exp | null;
    created_at?: timestamptz_comparison_exp | null;
    datetime?: timestamptz_comparison_exp | null;
    id?: uuid_comparison_exp | null;
    is_public?: Boolean_comparison_exp | null;
    location?: String_comparison_exp | null;
    location_point?: geometry_comparison_exp | null;
    name?: String_comparison_exp | null;
    open_signup_at?: timestamptz_comparison_exp | null;
    owner_id?: String_comparison_exp | null;
    performers?: performer_bool_exp | null;
    pin?: bpchar_comparison_exp | null;
    updated_at?: timestamptz_comparison_exp | null;
};
export type timestamptz_comparison_exp = {
    _eq?: unknown | null;
    _gt?: unknown | null;
    _gte?: unknown | null;
    _in?: Array<unknown> | null;
    _is_null?: boolean | null;
    _lt?: unknown | null;
    _lte?: unknown | null;
    _neq?: unknown | null;
    _nin?: Array<unknown> | null;
};
export type uuid_comparison_exp = {
    _eq?: unknown | null;
    _gt?: unknown | null;
    _gte?: unknown | null;
    _in?: Array<unknown> | null;
    _is_null?: boolean | null;
    _lt?: unknown | null;
    _lte?: unknown | null;
    _neq?: unknown | null;
    _nin?: Array<unknown> | null;
};
export type Boolean_comparison_exp = {
    _eq?: boolean | null;
    _gt?: boolean | null;
    _gte?: boolean | null;
    _in?: Array<boolean> | null;
    _is_null?: boolean | null;
    _lt?: boolean | null;
    _lte?: boolean | null;
    _neq?: boolean | null;
    _nin?: Array<boolean> | null;
};
export type String_comparison_exp = {
    _eq?: string | null;
    _gt?: string | null;
    _gte?: string | null;
    _ilike?: string | null;
    _in?: Array<string> | null;
    _iregex?: string | null;
    _is_null?: boolean | null;
    _like?: string | null;
    _lt?: string | null;
    _lte?: string | null;
    _neq?: string | null;
    _nilike?: string | null;
    _nin?: Array<string> | null;
    _niregex?: string | null;
    _nlike?: string | null;
    _nregex?: string | null;
    _nsimilar?: string | null;
    _regex?: string | null;
    _similar?: string | null;
};
export type geometry_comparison_exp = {
    _cast?: geometry_cast_exp | null;
    _eq?: unknown | null;
    _gt?: unknown | null;
    _gte?: unknown | null;
    _in?: Array<unknown> | null;
    _is_null?: boolean | null;
    _lt?: unknown | null;
    _lte?: unknown | null;
    _neq?: unknown | null;
    _nin?: Array<unknown> | null;
    _st_contains?: unknown | null;
    _st_crosses?: unknown | null;
    _st_d_within?: st_d_within_input | null;
    _st_equals?: unknown | null;
    _st_intersects?: unknown | null;
    _st_overlaps?: unknown | null;
    _st_touches?: unknown | null;
    _st_within?: unknown | null;
};
export type geometry_cast_exp = {
    geography?: geography_comparison_exp | null;
};
export type geography_comparison_exp = {
    _cast?: geography_cast_exp | null;
    _eq?: unknown | null;
    _gt?: unknown | null;
    _gte?: unknown | null;
    _in?: Array<unknown> | null;
    _is_null?: boolean | null;
    _lt?: unknown | null;
    _lte?: unknown | null;
    _neq?: unknown | null;
    _nin?: Array<unknown> | null;
    _st_d_within?: st_d_within_geography_input | null;
    _st_intersects?: unknown | null;
};
export type geography_cast_exp = {
    geometry?: geometry_comparison_exp | null;
};
export type st_d_within_geography_input = {
    distance: number;
    from: unknown;
    use_spheroid?: boolean | null;
};
export type st_d_within_input = {
    distance: number;
    from: unknown;
};
export type performer_bool_exp = {
    _and?: Array<performer_bool_exp> | null;
    _not?: performer_bool_exp | null;
    _or?: Array<performer_bool_exp> | null;
    _order?: Int_comparison_exp | null;
    created_at?: timestamptz_comparison_exp | null;
    id?: uuid_comparison_exp | null;
    intro_notes?: String_comparison_exp | null;
    name?: String_comparison_exp | null;
    openMic?: open_mic_bool_exp | null;
    open_mic_id?: uuid_comparison_exp | null;
    performance_complete?: Boolean_comparison_exp | null;
    updated_at?: timestamptz_comparison_exp | null;
    user_id?: String_comparison_exp | null;
};
export type Int_comparison_exp = {
    _eq?: number | null;
    _gt?: number | null;
    _gte?: number | null;
    _in?: Array<number> | null;
    _is_null?: boolean | null;
    _lt?: number | null;
    _lte?: number | null;
    _neq?: number | null;
    _nin?: Array<number> | null;
};
export type bpchar_comparison_exp = {
    _eq?: unknown | null;
    _gt?: unknown | null;
    _gte?: unknown | null;
    _ilike?: unknown | null;
    _in?: Array<unknown> | null;
    _iregex?: unknown | null;
    _is_null?: boolean | null;
    _like?: unknown | null;
    _lt?: unknown | null;
    _lte?: unknown | null;
    _neq?: unknown | null;
    _nilike?: unknown | null;
    _nin?: Array<unknown> | null;
    _niregex?: unknown | null;
    _nlike?: unknown | null;
    _nregex?: unknown | null;
    _nsimilar?: unknown | null;
    _regex?: unknown | null;
    _similar?: unknown | null;
};
export type performer_on_conflict = {
    constraint: performer_constraint;
    update_columns: Array<performer_update_column>;
    where?: performer_bool_exp | null;
};
export type NewMicMutationVariables = {
    object: open_mic_insert_input;
};
export type NewMicMutationResponse = {
    readonly insert_open_mic_one: {
        readonly id: string;
        readonly pin: unknown;
        readonly name: string;
        readonly location: string;
        readonly location_point: unknown | null;
        readonly datetime: unknown;
        readonly owner_id: string;
    } | null;
};
export type NewMicMutation = {
    readonly response: NewMicMutationResponse;
    readonly variables: NewMicMutationVariables;
};



/*
mutation NewMicMutation(
  $object: open_mic_insert_input!
) {
  insert_open_mic_one(object: $object) {
    id
    pin
    name
    location
    location_point
    datetime
    owner_id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "open_mic",
    "kind": "LinkedField",
    "name": "insert_open_mic_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location_point",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "owner_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewMicMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewMicMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8f6a48223c4aae1718a175483b25268",
    "id": null,
    "metadata": {},
    "name": "NewMicMutation",
    "operationKind": "mutation",
    "text": "mutation NewMicMutation(\n  $object: open_mic_insert_input!\n) {\n  insert_open_mic_one(object: $object) {\n    id\n    pin\n    name\n    location\n    location_point\n    datetime\n    owner_id\n  }\n}\n"
  }
};
})();
(node as any).hash = '75e5b1bd37a44e8cc5178d8217b6478b';
export default node;
