import {
    Environment,
    Network,
    Observable,
    RecordSource,
    Store,
    SubscribeFunction,
} from 'relay-runtime';
import { SubscriptionClient } from 'subscriptions-transport-ws';


const subscriptionClient = (token: string) => {
    if (!process.env.REACT_APP_GRAPHQL_RELAY_WS_URL) throw new Error("WS URL not configured")
    const headers: Record<string, string> = {};
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return new SubscriptionClient(process.env.REACT_APP_GRAPHQL_RELAY_WS_URL, {
        reconnect: true,
        connectionParams: {
            headers
        }
    });
};

const subscribe: (token: string) => SubscribeFunction = (token) => (request, variables) => {
    const subscribeObservable = subscriptionClient(token).request({
        query: request.text,
        operationName: request.name,
        variables,
    } as any) as any;
    // Important: Convert subscriptions-transport-ws observable type to Relay's
    return Observable.from(subscribeObservable);
};

const fetchQuery: (token: string) => Parameters<typeof Network.create>[0] = (token) => async (operation, variables) => {
    if (!process.env.REACT_APP_GRAPHQL_RELAY_URL) throw new Error("Relay GraphQL URL not configured")
    const headers: Record<string, string> = { 'Content-Type': 'application/json' };
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return fetch(process.env.REACT_APP_GRAPHQL_RELAY_URL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
    }).then(response => {
        return response.json();
    });
}


const envCache: Record<string, Environment> = {};
const relayStore = new Store(new RecordSource());
// TODO: Memoize
const getRelayEnvironment = (token: string ) => {
    if (envCache[token]) return envCache[token];
    envCache[token] = new Environment({
        network: Network.create(fetchQuery(token), subscribe(token)),
        store: relayStore,
    });
    return envCache[token];
}

export default getRelayEnvironment;