/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EditMicQueryVariables = {
    pin: unknown;
};
export type EditMicQueryResponse = {
    readonly open_mic_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly location: string;
                readonly location_point: unknown | null;
                readonly datetime: unknown;
                readonly pin: unknown;
                readonly owner_id: string;
                readonly open_signup_at: unknown;
                readonly close_signup_at: unknown;
            };
        }>;
    };
};
export type EditMicQuery = {
    readonly response: EditMicQueryResponse;
    readonly variables: EditMicQueryVariables;
};



/*
query EditMicQuery(
  $pin: bpchar!
) {
  open_mic_connection(where: {pin: {_eq: $pin}}, first: 1) {
    edges {
      node {
        id
        name
        location
        location_point
        datetime
        pin
        owner_id
        open_signup_at
        close_signup_at
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pin"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "pin"
              }
            ],
            "kind": "ObjectValue",
            "name": "pin"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "open_micConnection",
    "kind": "LinkedField",
    "name": "open_mic_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "open_micEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "open_mic",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location_point",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "datetime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "owner_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "open_signup_at",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "close_signup_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMicQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMicQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4f4b88098b18f9919cfbd80b77240e4",
    "id": null,
    "metadata": {},
    "name": "EditMicQuery",
    "operationKind": "query",
    "text": "query EditMicQuery(\n  $pin: bpchar!\n) {\n  open_mic_connection(where: {pin: {_eq: $pin}}, first: 1) {\n    edges {\n      node {\n        id\n        name\n        location\n        location_point\n        datetime\n        pin\n        owner_id\n        open_signup_at\n        close_signup_at\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7ba8c063bb473da46f2668b10c74d7f9';
export default node;
