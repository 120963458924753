import React, { memo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NewMic from "./new_mic/NewMic";
import PropsRoute from "../shared/components/PropsRoute";
import Home from "./home/Home";
import MicDetails from "./mic_details/MicDetails";
import PerformerSignup from "./performer_signup/PerformerSignup";
import PaperPageContainer from "../shared/components/PaperPageContainer";
import NearbyMics from "./nearby_mics/NearbyMics";
import UserMics from "./user_mics/UserMics";
import EditMic from "./edit_mic/EditMic";

function Routing() {
  return (
    <Switch>
      <PropsRoute
        exact
        path="/new"
        component={() => <PaperPageContainer content={<NewMic />} />}
      />
      <PropsRoute
        exact
        path="/nearby"
        component={() => <PaperPageContainer content={<NearbyMics />} />}
      />
      {/* TODO: require login on /mymics (+/new +/edit) at this level */}
      <PropsRoute
        exact
        path="/mymics"
        component={() => <PaperPageContainer content={<UserMics />} />}
      />
      <PropsRoute
        exact
        path="/mic/:pin"
        component={() => (
          <PaperPageContainer content={<MicDetails />} showHeader={false} />
        )}
      />
      <PropsRoute
        exact
        path="/edit/:pin"
        component={() => <PaperPageContainer content={<EditMic />} />}
      />
      <PropsRoute
        exact
        path="/signup/:pin"
        component={() => <PaperPageContainer content={<PerformerSignup />} />}
      />
      <PropsRoute exact path="/" component={Home} />
      <Route render={() => <Redirect to="/" />} />{" "}
      {/* catchall, redirects unmatched paths to "/" */}
    </Switch>
  );
}

export default memo(Routing);
