/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PerformerSignupMutationVariables = {
    introNotes: string;
    name: string;
    openMicId: unknown;
    userId: string;
};
export type PerformerSignupMutationResponse = {
    readonly insert_performer_one: {
        readonly id: string;
        readonly intro_notes: string | null;
        readonly name: string;
        readonly open_mic_id: unknown;
        readonly user_id: string | null;
        readonly _order: number | null;
    } | null;
};
export type PerformerSignupMutation = {
    readonly response: PerformerSignupMutationResponse;
    readonly variables: PerformerSignupMutationVariables;
};



/*
mutation PerformerSignupMutation(
  $introNotes: String!
  $name: String!
  $openMicId: uuid!
  $userId: String!
) {
  insert_performer_one(object: {intro_notes: $introNotes, name: $name, open_mic_id: $openMicId, user_id: $userId}) {
    id
    intro_notes
    name
    open_mic_id
    user_id
    _order
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "introNotes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "openMicId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "intro_notes",
            "variableName": "introNotes"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "open_mic_id",
            "variableName": "openMicId"
          },
          {
            "kind": "Variable",
            "name": "user_id",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "object"
      }
    ],
    "concreteType": "performer",
    "kind": "LinkedField",
    "name": "insert_performer_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "intro_notes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "open_mic_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_order",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformerSignupMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformerSignupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a378cf5ca886eb026a33f6dba77961e6",
    "id": null,
    "metadata": {},
    "name": "PerformerSignupMutation",
    "operationKind": "mutation",
    "text": "mutation PerformerSignupMutation(\n  $introNotes: String!\n  $name: String!\n  $openMicId: uuid!\n  $userId: String!\n) {\n  insert_performer_one(object: {intro_notes: $introNotes, name: $name, open_mic_id: $openMicId, user_id: $userId}) {\n    id\n    intro_notes\n    name\n    open_mic_id\n    user_id\n    _order\n  }\n}\n"
  }
};
})();
(node as any).hash = '112361c68f38fc7648f2789dfbe8a982';
export default node;
