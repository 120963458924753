// Firebase Config
const firebaseConfig = {
    apiKey: "AIzaSyDpY99gamQUjaETOYpfO2fWB-Bsmmx8YHM",
    authDomain: "litmic-2021.firebaseapp.com",
    databaseURL: "https://litmic-2021-default-rtdb.firebaseio.com",
    projectId: "litmic-2021",
    storageBucket: "litmic-2021.appspot.com",
    messagingSenderId: "598073376526",
    appId: "1:598073376526:web:8b0c09e3e93177e6bbe15f",
    measurementId: "G-BHESPE4V9W"
};

export default firebaseConfig;