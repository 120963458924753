import React from "react";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import {
  makeStyles,
  Typography,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import { Link as RouterLink } from "react-router-dom";
import path from "path";
import moment from "moment";
import { OpenMicList_list$key } from "./__generated__/OpenMicList_list.graphql";

const useStyles = makeStyles((theme) => ({
  micList: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const OpenMicList = (props: { openMics: OpenMicList_list$key }) => {
  const classes = useStyles();
  const { edges } = useFragment(
    graphql`
      fragment OpenMicList_list on open_micConnection {
        edges {
          node {
            id
            name
            location
            location_point
            datetime
            pin
            owner_id
          }
        }
      }
    `,
    props.openMics
  );

  if (edges.length === 0) {
    return (
      <Typography variant="h4" align="center" style={{ marginTop: "1em" }}>
        No Mics Found!
      </Typography>
    );
  }
  return (
    <List className={classes.micList}>
      {edges.map(({ node }) => (
        <Link
          key={node.id}
          to={path.join("/mic", node.pin as string)}
          component={RouterLink}
          underline="none"
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MicIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={node.name}
              secondary={
                <>
                  {moment(node.datetime as any).format("MMMM Do, h:mm a")}
                  <br />
                  {node.location}
                </>
              }
            />
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default OpenMicList;
