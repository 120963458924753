import React, { memo, useCallback, Fragment } from "react";
import path from "path";
import { Link, useHistory } from "react-router-dom";
import { Theme, withStyles, TextField, Button, Grid } from "@material-ui/core";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import firebase from "firebase/app";
import LitMicHeader from "../../shared/components/LitMicHeader";

const styles = (theme: Theme) => ({
  pinInput: {
    margin: "20px 0 20px 0",
  },
});

function Home(props: any) {
  const history = useHistory();
  const { classes } = props;
  const [openMicPin, setOpenMicPin] = React.useState("");

  if (openMicPin !== openMicPin.toUpperCase()) {
    setOpenMicPin(openMicPin.toUpperCase().slice(0, 4));
  }

  const handleChangeOpenMicPin = useCallback(
    (event) => {
      setOpenMicPin(event.target.value);
    },
    [setOpenMicPin]
  );

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      history.push(path.join("/mic", openMicPin));
    },
    [history, openMicPin]
  );

  // Really messy, should be broken up
  return (
    <Fragment>
      <LitMicHeader />
      <div style={{ height: "15vh" }}></div>

      <Grid container direction="row" justify="center">
        <Grid item xs={10} md={4}>
          <form onSubmit={onFormSubmit}>
            <TextField
              label="Open Mic PIN"
              variant="filled"
              fullWidth
              required
              inputProps={{
                autoComplete: "off",
                minLength: 4,
                maxLength: 4,
                pattern: "[A-Z0-9]*",
              }}
              value={openMicPin}
              onChange={handleChangeOpenMicPin}
              className={classes.pinInput}
            />
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>

      <div style={{ height: "3vh" }}></div>

      <Grid container direction="row" justify="center">
        <Grid item xs={10} md={4}>
          <Link to="/nearby" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Mics Near Me
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="center">
        <Grid item xs={10} md={4}>
          <FirebaseAuthConsumer>
            {({ isSignedIn, providerId }) => {
              if (!isSignedIn || providerId === "anonymous") {
                return (
                  <Fragment>
                    <div style={{ height: "15vh" }}></div>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                        firebase.auth().signInWithPopup(googleAuthProvider);
                      }}
                    >
                      Sign In with Google
                    </Button>
                  </Fragment>
                );
              }
              return (
                <Fragment>
                  <div style={{ height: "4vh" }}></div>

                  <Link to="/mymics" style={{ textDecoration: "none" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      My Mics
                    </Button>
                  </Link>
                  <div style={{ height: "8vh" }}></div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "45%" }}
                    onClick={() => {
                      firebase.auth().signOut();
                    }}
                  >
                    Sign Out
                  </Button>
                  {/* TODO consider https://stackoverflow.com/a/55693040 */}
                  <Link
                    to="/new"
                    style={{ textDecoration: "none", marginLeft: "10%" }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ width: "45%" }}
                    >
                      New Mic
                    </Button>
                  </Link>
                </Fragment>
              );
            }}
          </FirebaseAuthConsumer>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(memo(Home));
