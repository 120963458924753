import React, { Suspense, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import getRelayEnvironment from "./relayEnvironment";
import theme from "./theme";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { HashRouter } from "react-router-dom";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import Routing from "./components/Routing";
import firebaseConfig from "./firebaseConfig";
import LoadingSpinner from "./shared/components/LoadingSpinner";

function App() {
  const [authToken, setAuthToken] = useState("");

  // https://hasura.io/blog/authentication-and-authorization-using-hasura-and-firebase/
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaim) {
          setAuthToken(token);
        } else {
          // Check if refresh is required.
          const metadataRef = firebase
            .database()
            .ref("metadata/" + user.uid + "/refreshTime");

          metadataRef.on("value", async (data) => {
            if (!data.exists) return;
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            setAuthToken(token);
          });
        }
      } else {
        setAuthToken("");
      }
    });
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
          <RelayEnvironmentProvider
            environment={getRelayEnvironment(authToken)}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <Routing />
            </Suspense>
          </RelayEnvironmentProvider>
        </FirebaseAuthProvider>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default App;
