/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OpenMicPerformerList_performers = {
    readonly performers_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly _order: number | null;
                readonly name: string;
                readonly intro_notes: string | null;
                readonly open_mic_id: unknown;
                readonly user_id: string | null;
                readonly performance_complete: boolean;
            };
        }>;
    };
    readonly " $refType": "OpenMicPerformerList_performers";
};
export type OpenMicPerformerList_performers$data = OpenMicPerformerList_performers;
export type OpenMicPerformerList_performers$key = {
    readonly " $data"?: OpenMicPerformerList_performers$data;
    readonly " $fragmentRefs": FragmentRefs<"OpenMicPerformerList_performers">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenMicPerformerList_performers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "performerConnection",
      "kind": "LinkedField",
      "name": "performers_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "performerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "performer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "_order",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "intro_notes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "open_mic_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "performance_complete",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "open_mic",
  "abstractKey": null
};
(node as any).hash = 'e1713fc2230e7c61478407fd2b66923a';
export default node;
