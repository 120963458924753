/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type open_mic_set_input = {
    close_signup_at?: unknown | null;
    created_at?: unknown | null;
    datetime?: unknown | null;
    id?: unknown | null;
    is_public?: boolean | null;
    location?: string | null;
    location_point?: unknown | null;
    name?: string | null;
    open_signup_at?: unknown | null;
    owner_id?: string | null;
    pin?: unknown | null;
    updated_at?: unknown | null;
};
export type EditMicMutationVariables = {
    id: unknown;
    setValues: open_mic_set_input;
};
export type EditMicMutationResponse = {
    readonly update_open_mic_by_pk: {
        readonly id: string;
        readonly name: string;
        readonly location: string;
        readonly location_point: unknown | null;
        readonly datetime: unknown;
        readonly pin: unknown;
        readonly open_signup_at: unknown;
        readonly close_signup_at: unknown;
    } | null;
};
export type EditMicMutation = {
    readonly response: EditMicMutationResponse;
    readonly variables: EditMicMutationVariables;
};



/*
mutation EditMicMutation(
  $id: uuid!
  $setValues: open_mic_set_input!
) {
  update_open_mic_by_pk(_set: $setValues, pk_columns: {id: $id}) {
    id
    name
    location
    location_point
    datetime
    pin
    open_signup_at
    close_signup_at
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "setValues"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "setValues"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "open_mic",
    "kind": "LinkedField",
    "name": "update_open_mic_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location_point",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "datetime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "open_signup_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "close_signup_at",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMicMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMicMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bda9e51a3ca7b4e06896f2a1adf1a4b9",
    "id": null,
    "metadata": {},
    "name": "EditMicMutation",
    "operationKind": "mutation",
    "text": "mutation EditMicMutation(\n  $id: uuid!\n  $setValues: open_mic_set_input!\n) {\n  update_open_mic_by_pk(_set: $setValues, pk_columns: {id: $id}) {\n    id\n    name\n    location\n    location_point\n    datetime\n    pin\n    open_signup_at\n    close_signup_at\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f008e8dda61968c9566623162d120314';
export default node;
