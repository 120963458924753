import React, { Fragment, useState, useCallback, useRef } from "react";
import { Button, Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import path from "path";

import ButtonCircularProgress from "../../shared/components/ButtonCircularProgress";
import MicOptions from "../../shared/components/MicOptions";
import { NewMicMutation } from "./__generated__/NewMicMutation.graphql";
import firebase from "firebase/app";
import "firebase/auth";
import { LocationValueType } from "../../shared/components/LocationAutocomplete";
import moment from "moment";

function NewMic() {
  const history = useHistory();
  const [micDatetime, setMicDatetime] = useState(new Date());
  const [micOpenSignupDatetime, setMicOpenSignupDatetime] = useState(
    moment(micDatetime).subtract(30, "minutes").toDate()
  );
  const [micCloseSignupDatetime, setMicCloseSignupDatetime] = useState(
    moment(micDatetime).add(1, "hour").toDate()
  );
  const [micLocation, setMicLocation] = useState<LocationValueType>({
    location: "",
  });
  const micName = useRef<HTMLInputElement>();

  const [
    createOpenMicMutation,
    createInFlight,
  ] = useMutation<NewMicMutation>(graphql`
    mutation NewMicMutation($object: open_mic_insert_input!) {
      insert_open_mic_one(object: $object) {
        id
        pin
        name
        location
        location_point
        datetime
        owner_id
      }
    }
  `);

  const onFormSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!createInFlight) {
        const user = firebase.auth().currentUser;

        if (!user || user.isAnonymous) {
          return alert("Sign in to create an open mic");
        }

        let location_point: any = null;
        if (micLocation.lat != null && micLocation.lng != null)
          location_point = {
            type: "Point",
            coordinates: [micLocation.lng, micLocation.lat], // PostGIS order
            crs: { type: "name", properties: { name: "EPSG:4326" } },
          };
        createOpenMicMutation({
          variables: {
            object: {
              datetime: micDatetime,
              location: micLocation.location,
              location_point,
              name: micName.current?.value as string,
              owner_id: user.uid,
              open_signup_at: micOpenSignupDatetime,
              close_signup_at: micCloseSignupDatetime,
            },
          },
          onCompleted: (response) => {
            response.insert_open_mic_one?.pin &&
              history.push(
                path.join("/mic", response.insert_open_mic_one.pin as string)
              );
          },
        });
      }
    },
    [
      history,
      micDatetime,
      micOpenSignupDatetime,
      micCloseSignupDatetime,
      micLocation,
      createOpenMicMutation,
      createInFlight,
    ]
  );

  return (
    <Fragment>
      <Typography variant="h3" align="center" className="sm-mg-bottom">
        New Mic
      </Typography>
      <form onSubmit={onFormSubmit}>
        <MicOptions
          micDatetime={micDatetime}
          onChangeMicDatetime={setMicDatetime}
          micOpenSignupDatetime={micOpenSignupDatetime}
          onChangeMicOpenSignupDatetime={setMicOpenSignupDatetime}
          micCloseSignupDatetime={micCloseSignupDatetime}
          onChangeMicCloseSignupDatetime={setMicCloseSignupDatetime}
          micName={micName}
          micLocation={micLocation}
          onChangeMicLocation={setMicLocation}
        />
        <br />
        <Grid container justify="flex-end">
          <Button onClick={() => history.goBack()}>Back</Button>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={createInFlight}
          >
            Save {createInFlight && <ButtonCircularProgress />}
          </Button>
        </Grid>
      </form>
    </Fragment>
  );
}

export default NewMic;
