// https://react-google-maps-api-docs.netlify.app/#autocomplete
import React from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { TextField } from "@material-ui/core";

const googleLibraries = ["places"];

export type LocationValueType = {
  location: string;
  lat?: number;
  lng?: number;
};

const LocationAutocomplete = (props: {
  required: boolean;
  value: LocationValueType;
  onChange: (v: LocationValueType) => void;
}) => {
  const [autocomplete, setAutocomplete] = React.useState<any>(null);

  const onLoad = setAutocomplete;
  const onUnmount = React.useCallback(() => {
    setAutocomplete(null);
  }, [setAutocomplete]);

  const onPlaceChanged = React.useCallback(() => {
    if (autocomplete) {
      const p = autocomplete.getPlace();
      const locationName = [p.name, p.formatted_address]
        .filter(Boolean)
        .join(", ")
        .replace(", USA", "");
      props.onChange({
        location: locationName,
        lat: p.geometry?.location.lat(),
        lng: p.geometry?.location.lng(),
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }, [autocomplete, props]);

  return (
    <LoadScript
      libraries={googleLibraries as any}
      googleMapsApiKey="AIzaSyDpY99gamQUjaETOYpfO2fWB-Bsmmx8YHM"
    >
      <Autocomplete
        {...{ onLoad, onUnmount, onPlaceChanged }}
        fields={["name", "formatted_address", "geometry.location"]}
        className="MuiFormControl-fullWidth"
      >
        <TextField
          label="Mic Location"
          fullWidth
          required={props.required}
          value={props.value.location}
          onChange={(e) => props.onChange({ location: e.target.value })}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default LocationAutocomplete;
