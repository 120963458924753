/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserMicsQueryVariables = {
    userId: string;
};
export type UserMicsQueryResponse = {
    readonly open_mic_connection: {
        readonly " $fragmentRefs": FragmentRefs<"OpenMicList_list">;
    };
};
export type UserMicsQuery = {
    readonly response: UserMicsQueryResponse;
    readonly variables: UserMicsQueryVariables;
};



/*
query UserMicsQuery(
  $userId: String!
) {
  open_mic_connection(where: {owner_id: {_eq: $userId}}, order_by: {datetime: desc}) {
    ...OpenMicList_list
  }
}

fragment OpenMicList_list on open_micConnection {
  edges {
    node {
      id
      name
      location
      location_point
      datetime
      pin
      owner_id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "datetime": "desc"
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "owner_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMicsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "open_micConnection",
        "kind": "LinkedField",
        "name": "open_mic_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OpenMicList_list"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserMicsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "open_micConnection",
        "kind": "LinkedField",
        "name": "open_mic_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "open_micEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "open_mic",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location_point",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "datetime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "owner_id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9ec9e46cea80f2bb76cdd97b4fb0cf2",
    "id": null,
    "metadata": {},
    "name": "UserMicsQuery",
    "operationKind": "query",
    "text": "query UserMicsQuery(\n  $userId: String!\n) {\n  open_mic_connection(where: {owner_id: {_eq: $userId}}, order_by: {datetime: desc}) {\n    ...OpenMicList_list\n  }\n}\n\nfragment OpenMicList_list on open_micConnection {\n  edges {\n    node {\n      id\n      name\n      location\n      location_point\n      datetime\n      pin\n      owner_id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4a4e1daef7ac4e7c2f3f830c514f03c7';
export default node;
