import React from "react";
import { Grid } from "@material-ui/core";
import PacmanLoader from "react-spinners/PacmanLoader";

const LoadingSpinner = () => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid
        item
        xs={12}
        md={2}
        style={{ marginTop: "40vh", marginLeft: "-5em" }}
      >
        <PacmanLoader color={"#fabb6e"} loading={true} size={60} />
      </Grid>
    </Grid>
  );
};

export default LoadingSpinner;
