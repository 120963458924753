import React, { useRef, useCallback, useEffect } from "react";
import path from "path";
import {
  TextField,
  List,
  ListItem,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import { PerformerSignupMutation } from "./__generated__/PerformerSignupMutation.graphql";
import { decodeRelayId } from "../../shared/functions/decodeRelayId";
import { PerformerSignupQuery } from "./__generated__/PerformerSignupQuery.graphql";
import firebase from "firebase/app";
import "firebase/auth";
import moment from "moment";

const PerformerSignup = () => {
  const history = useHistory();
  const performerName = useRef<HTMLInputElement>();
  const introNotes = useRef<HTMLInputElement>();
  const { pin } = useParams<{ pin: string }>();

  // Shouldn't need this here - but required for now to map pin -> uuid for now :(
  // Now also used to check signup time
  const { open_mic_connection } = useLazyLoadQuery<PerformerSignupQuery>(
    graphql`
      query PerformerSignupQuery($pin: bpchar!) {
        open_mic_connection(where: { pin: { _eq: $pin } }) {
          edges {
            node {
              id
              pin
              open_signup_at
              close_signup_at
            }
          }
        }
      }
    `,
    { pin }
  );
  const openMic = open_mic_connection?.edges?.[0]?.node;

  const [
    createPerformerMutation,
    createInFlight,
  ] = useMutation<PerformerSignupMutation>(graphql`
    mutation PerformerSignupMutation(
      $introNotes: String!
      $name: String!
      $openMicId: uuid!
      $userId: String!
    ) {
      insert_performer_one(
        object: {
          intro_notes: $introNotes
          name: $name
          open_mic_id: $openMicId
          user_id: $userId
        }
      ) {
        id
        intro_notes
        name
        open_mic_id
        user_id
        _order
      }
    }
  `);

  // TODO: HACK HERE DO THIS BETTER
  // Forces user to at least have "anonymous" user for signup, would prefer to do directly before signup mutation,
  // but seems like firebase function to set custom jwts claims does not trigger in time.
  useEffect(() => {
    (async () => {
      let user = firebase.auth().currentUser;
      if (!user) {
        firebase.auth().signInAnonymously();
      }
    })();
  }, []);

  const onFormSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      if (!createInFlight) {
        let user = firebase.auth().currentUser;
        createPerformerMutation({
          variables: {
            name: performerName.current?.value as string,
            introNotes: introNotes.current?.value as string,
            openMicId: decodeRelayId(openMic.id),
            userId: user?.uid as string,
          },
          onCompleted: () => history.push(path.join("/mic", pin)),
        });
      }
    },
    [
      introNotes,
      performerName,
      history,
      openMic,
      createPerformerMutation,
      createInFlight,
      pin,
    ]
  );

  const signupsEnabled =
    openMic &&
    moment().isBetween(
      openMic.open_signup_at as string,
      openMic.close_signup_at as string
    );

  // TODO: Refac the following 10 lines are shared with MicDetails
  const openMicAny = openMic as any; // Idk, avoiding "complex expression in the dependency array"
  useEffect(() => {
    if (!openMicAny || !signupsEnabled) {
      setTimeout(() => history.goBack(), 2000);
    }
  }, [history, openMicAny, signupsEnabled]);

  if (!openMic) {
    return (
      <Typography variant="h4" align="center">
        Open Mic Not Found!
      </Typography>
    );
  }

  if (!signupsEnabled) {
    return (
      <Typography variant="h4" align="center">
        Signup Closed
      </Typography>
    );
  }

  return (
    <form onSubmit={onFormSubmit}>
      <Typography variant="h4" align="center" className="sm-mg-bottom">
        Performer Signup {/* for mic.name */}
      </Typography>
      <List disablePadding>
        <ListItem>
          <TextField
            required
            label="Performer Name"
            fullWidth
            inputRef={performerName}
          />
        </ListItem>
        <ListItem>
          <TextField label="Intro Notes" fullWidth inputRef={introNotes} />
        </ListItem>
      </List>
      <br />
      <Grid container justify="flex-end">
        <Button onClick={() => history.goBack()}>Back</Button>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={createInFlight}
        >
          Sign Up
        </Button>
      </Grid>
    </form>
  );
};

export default PerformerSignup;
