import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Grid, Box, makeStyles, Link } from "@material-ui/core";
import { ReactComponent as LitMicIcon } from "../LitMicIcon.svg";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    borderColor: "#fabb6e",
    backgroundImage:
      "linear-gradient(to bottom right, transparent, 80%, #fabb6e)",
  },
  brandText: {
    fontFamily: "'Pattaya', sans-serif",
    // fontSize: '70px',
  },
}));

const LitMicHeader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.titleBox} borderBottom={1} paddingY="2.5vh">
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <Link to={"/"} component={RouterLink} underline="none">
            <LitMicIcon height="5em" style={{ marginBottom: "1em" }} />
          </Link>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h1"
            className={classes.brandText}
            display="inline"
            color="textPrimary"
          >
            LitMic
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LitMicHeader;
