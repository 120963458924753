/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PerformerSignupQueryVariables = {
    pin: unknown;
};
export type PerformerSignupQueryResponse = {
    readonly open_mic_connection: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly pin: unknown;
                readonly open_signup_at: unknown;
                readonly close_signup_at: unknown;
            };
        }>;
    };
};
export type PerformerSignupQuery = {
    readonly response: PerformerSignupQueryResponse;
    readonly variables: PerformerSignupQueryVariables;
};



/*
query PerformerSignupQuery(
  $pin: bpchar!
) {
  open_mic_connection(where: {pin: {_eq: $pin}}) {
    edges {
      node {
        id
        pin
        open_signup_at
        close_signup_at
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pin"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "pin"
              }
            ],
            "kind": "ObjectValue",
            "name": "pin"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "open_micConnection",
    "kind": "LinkedField",
    "name": "open_mic_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "open_micEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "open_mic",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "open_signup_at",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "close_signup_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformerSignupQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformerSignupQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82f51909da38b60a6a00ad66654a4b99",
    "id": null,
    "metadata": {},
    "name": "PerformerSignupQuery",
    "operationKind": "query",
    "text": "query PerformerSignupQuery(\n  $pin: bpchar!\n) {\n  open_mic_connection(where: {pin: {_eq: $pin}}) {\n    edges {\n      node {\n        id\n        pin\n        open_signup_at\n        close_signup_at\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5cfc4dd2b4e93ca1edd936eecd4239f4';
export default node;
