import {  useEffect, useRef } from 'react';

type Callback = () => void;

// https://stackoverflow.com/a/53395342
export function useInterval(callback: Callback, delay: number) {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current?.();
    }, delay);
    return () => clearInterval(id);
  }, [delay, savedCallback]);
}