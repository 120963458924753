import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withWidth, Grid, Paper, makeStyles } from "@material-ui/core";
import LitMicHeader from "./LitMicHeader";

const useStyles = makeStyles((theme) => ({
  containerFix: {
    [theme.breakpoints.down("xl")]: {
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    overflow: "hidden",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: "auto",
  },
  paperContainer: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2),
    },
  },
}));

function PaperPageContainer(props: { content: any; showHeader?: boolean }) {
  const classes = useStyles();
  const { content, showHeader = true } = props;
  return (
    <Fragment>
      {showHeader && <LitMicHeader />}
      <Grid container justify="center" className={classes.containerFix}>
        <Grid item xs={12} lg={12}>
          <Paper className={classes.paperContainer}>{content}</Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

PaperPageContainer.propTypes = {
  content: PropTypes.element.isRequired,
};

export default withWidth()(PaperPageContainer);
