import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
//@ts-ignore
import { graphql } from "babel-plugin-relay/macro";
import firebase from "firebase/app";
import "firebase/auth";
import { UserMicsQuery } from "./__generated__/UserMicsQuery.graphql";
import OpenMicList from "../../shared/components/OpenMicList";
import { useHistory } from "react-router";

const UserMics = () => {
  const history = useHistory();
  const user = firebase.auth().currentUser;

  const { open_mic_connection } = useLazyLoadQuery<UserMicsQuery>(
    graphql`
      query UserMicsQuery($userId: String!) {
        open_mic_connection(
          where: { owner_id: { _eq: $userId } }
          order_by: { datetime: desc }
        ) {
          ...OpenMicList_list
        }
      }
    `,
    {
      userId: user?.uid ?? "",
    }
  );

  if (!user || user.isAnonymous) {
    alert("Sign in view your open mics!");
    history.push("/");
  }

  return <OpenMicList openMics={open_mic_connection} />;
};

export default UserMics;
